import {
  Upload,
  Row,
  Form,
  Col,
  Image,
  Select,
  Button,
  Modal,
} from "antd";
import TableAl from "../../components/tables/table";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveProductReducer,
  activateProductReducer,
  getRemovedProductReducer,
  deleteProductReducer,
  getProductById,
} from "../../store/productReducer";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  UploadOutlined,
  DeleteOutlined,
  EditOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import ProductSearch from '../../components/base/productSearch'

const ViewProduct = () => {
  const navigate = useHistory();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const [statuss, setStatus] = useState("");
  const [actionss, setActionss] = useState("");
  const [productId, setProductId] = useState("");
  const { isLoading } = useSelector((state) => state.category);
  const { products } = useSelector((state) => state.product);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ setFile] = useState(null);

  const handleChange = (info) => {
    if (info.file.status === "done") {
      toast.success(`${info.file.name} file uploaded successfully`);
      setFile(info.file); // Update the file state
    } else if (info.file.status === "error") {
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toast.info("You can only upload JPG/PNG files!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 < 100;
    if (!isLt2M) {
      toast.info("Image must be smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };



  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("product_image", file); // Append the file to form data

    try {
      await axios.post(
        "https://ethiodererp.com/api/images/save/product/" + productId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "authorization": `${localStorage.getItem("token")}`
          },
        }
      );
      onSuccess(
        dispatch(getActiveProductReducer())
      ); // Call onSuccess with the response
    } catch (error) {// Call onError in case of failure
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveProductReducer());
    setActionss(true);
  }, [dispatch]);




  const handlePreview = (imageUrl) => {
    setPreviewImage(imageUrl);
    setPreviewOpen(true);
  };
  const addNewImage = (id) => {
    setProductId(id);
    setIsModalOpen(true);
  };

  const handleDelete = (productData) => {
    Modal.confirm({
      centered: true,
      okCancel: "Cancel",
      okText: "Delete",
      onOk: () => dispatch(deleteProductReducer({ id: productData._id })),
      title: "Confirmation",
      content: "Are you sure to delete this product",
    });
  };

  const handleActivate = (productData) => {
    Modal.confirm({
      centered: true,
      okCancel: "Cancel",
      okText: "Activate",
      onOk: () => dispatch(activateProductReducer({ id: productData._id })),
      title: "Confirmation",
      content: "Are you sure to Activate this product",
    });
  };

  const handlStatus = (eve) => {
    if (eve === "active") {
      setActionss(true);
      setStatus(false);
      dispatch(getActiveProductReducer());
    } else if (eve === "removed") {
      setActionss(false);
      setStatus(true);
      dispatch(getRemovedProductReducer());
    }
  };


  const editProduct = (productDataOnclick) => {
    navigate.push("/addproduct/" + productDataOnclick._id);
    dispatch(getProductById(productDataOnclick));
  };
  const Image_url = "https://ethiodererp.com/images/";

  const colummm = [
    {
      title: "Product Name",
      width: "35%",
      key: "name",
      render: (text, record) => (
        <span style={{ display: "flex", flexDirection: "column", gap: "4" }}>
          <h5>{record.name}</h5>
          <p>{record.description}</p>
        </span>
      ),
    },
    {
      title: "Product Image",
      render: (text, record) => (
        <span>
          {record.image_extension ? (
            <Image
              width={100}
              src={Image_url + record._id + record.image_extension}
              onClick={() =>
                handlePreview(Image_url + record._id + record.image_extension)
              }
              style={{ cursor: "pointer" }}
              preview={{
                visible: previewOpen,
                src: previewImage,
                onVisibleChange: (visible) => setPreviewOpen(visible),
              }}
            />
          ) : (
            <Button onClick={() => addNewImage(record._id)}>Add Image</Button>
          )}
        </span>
      ),
    },
    {
      title: "Product Category",
      render: (text, record) => <span>{record.category?.name}</span>,
    },

    {
      title: "specifications",
      render: (text, record) => (
        <ul>
          {record?.specifications?.map((item, index) => (
            <li key={index}>
              <b>{item.name}</b>: {item.value}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "Quantity Measurements",
      render: (text, record) => (
        <ul>
          {record?.quantity?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ),
    },
  ];
  if (crdentialDataForOne.role === "warehouseAdmin") {
    colummm.push({
      title: "Actions",
      render: (text, record) => (
        <span>
          {actionss && (
            <Button
              onClick={() => editProduct(record)}
              style={{ marginRight: 8 }}
              icon={<EditOutlined />}
            ></Button>
          )}
          {actionss && (
            <Button
              onClick={() => handleDelete(record)}
              icon={<DeleteOutlined />}
            ></Button>
          )}
          {statuss && (
            <Button
              onClick={() => handleActivate(record)}
              icon={<UndoOutlined />}
            ></Button>
          )}
        </span>
      ),
    });
  }
  return (
    <div>
      <h2 className="text-xl mb-2">List of Products</h2>
      <Modal
        title="Add image product"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Upload
          customRequest={customRequest}
          showUploadList={false} // Hide the upload list
          beforeUpload={beforeUpload}
          onChange={handleChange}
          accept=".jpg,.jpeg,.png" // Accept only JPG and PNG files
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </Modal>
      <Row gutter={20}>
        
        
        <Col xs={24} sm={24} lg={8}>
          {crdentialDataForOne.role === "admin" && (
            <Form.Item>
              <Select
                className="mr-2"
                style={{width:'200px'}}
                onChange={handlStatus}
                placeholder="Select Category Status"
              >
                <option value="active" className="" selected>
                  Active
                </option>
                <option value="removed">Removed</option>
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <ProductSearch />
        </Col>
        <Col xs={24} sm={24} lg={8}>
          {crdentialDataForOne?.role === "warehouseAdmin" && (
            <Button
              style={{ backgroundColor: "#5856d6", color: "white" }}
              onClick={() => navigate.push("/addproduct")}
              className="bg-blue-300"
            >
              Add new product
            </Button>
          )}
        </Col>
      </Row>

      <div>
        <TableAl
          columnnn={colummm}
          isLoadinggg={isLoading}
          dataaa={products?.data}
        />
      </div>
    </div>
  );
};
export default ViewProduct;
